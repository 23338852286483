import {
  Button,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Modal,
  Wrap,
  Box,
  ModalFooter,
  ButtonGroup,
  Text,
  FormLabel,
  HStack,
  Alert,
  AlertDescription,
  AlertIcon,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { Form, Formik, Field } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  delEntity,
  patchEntity,
  postEntity,
  selectEntities,
  selectSaveError,
  selectSaveSuccess,
  setSaveSuccess,
  setSaveSuccessMessage,
  selectSaveSuccessMessage,
} from '../../slices/masterData/entityManagerSlice';
import FieldRender, { FieldErrorMessage } from './fields/FieldRender';
import SimpleReferenceSelectField from './fields/SimpleReferenceSelectField';
import Tooltip from '../quality/_components/Tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const validField = field => {
  if (field.hidden && field.hidden === true) {
    return false;
  }
  return field.name && field.displayName && field.dataType;
};

const generateDefaultValues = (theseEntities, foundEntity) => {
  if (!theseEntities.entityDefinition.fields || !foundEntity) {
    return {};
  }

  const defaultValues = theseEntities.entityDefinition.fields.reduce((acc, field) => {
    const result = { ...acc };
    // Hidden field such as ID, doesn't need to be updated.
    if (field.hidden === true) return result;
    if (field.referenceType && field.referenceType === 'nested') {
      if (!foundEntity[field.name]) {
        result[field.name] = {};
      } else {
        Object.entries(foundEntity[field.name]).map(([key, value]) => {
          // Nested fields needs prefix.
          if (!result[field.name]) {
            result[field.name] = {};
          }
          result[field.name][key] = value;
        });
      }
    } else if (field.referenceType && field.referenceType === 'multi-nested') {
      if (!foundEntity[field.name]) {
        result[field.name] = [];
      } else {
        foundEntity[field.name].map((entity, index) => {
          // Nested fields needs prefix.
          Object.entries(entity).map(([key, value]) => {
            if (!result[field.name]) {
              result[field.name] = [];
            }
            if (!result[field.name][index]) {
              result[field.name][index] = {};
            }
            result[field.name][index][key] = value;
          });
        });
      }
    } else if (field.dataType === 'boolean') {
      result[field.name] = foundEntity[field.name] ? 'true' : 'false';
    } else {
      result[field.name] = foundEntity[field.name];
    }
    return result;
  }, {});

  return defaultValues;
};

const generateYupSchema = (entityConfig, referencedEntities) => {
  const yupShape = {};

  if (entityConfig.fields) {
    entityConfig.fields
      .filter(field => !field.hidden)
      .forEach(field => {
        let yupField;
        switch (field.dataType) {
          case 'date':
            yupField = Yup.date().typeError('Invalid date');
            break;
          case 'shorttext':
            yupField = Yup.string()
            .transform((value) => (value === "" ? null : value)) 
            .nullable()
            .max(60, "Short text too long");
            break;
          case 'text':
            yupField = Yup.string()
            .transform((value) => (value === "" ? null : value))
            .nullable()
            break;
          case 'simple-reference':
            yupField = Yup.string()
            .transform((value) => (value === "" ? null : value))
            .nullable()
            break;
          case 'longtext':
            yupField = Yup.string();
            break;
          case 'integer':
            yupField = Yup.number().integer().typeError('Invalid integer value');
            break;
          case 'number':
            yupField = Yup.number().typeError('Invalid numeric value');
            break;
          case 'reference':
            if (field.referenceType == 'nested') {
              yupField = generateYupSchema(
                referencedEntities[field.referenceEntityType].entityDefinition,
                referencedEntities
              );
            } else if (field.referenceType == 'multi-nested') {
              yupField = Yup.array().of(
                generateYupSchema(
                  referencedEntities[field.referenceEntityType].entityDefinition,
                  referencedEntities
                ).nullable()
              );
            } else {
              yupField = Yup.string();
            }
            break;
          default:
            yupField = Yup.string()
            break;
        }
        if (yupField) {
          if (field.required) {
            yupField = yupField.required(`${field.displayName} field required`);
          } else {
            yupField = yupField.nullable();
          }
        }

        yupShape[field.name] = yupField;
      });
  }

  return Yup.object().shape(yupShape);
};

const EntityEditor = ({ entityDefinition, referencedEntities, activeEntityid, showEditModel, setShowEditModel }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const allEntities = useSelector(selectEntities);
  const saveError = useSelector(selectSaveError);
  const saveSuccessMessage = useSelector(selectSaveSuccessMessage);
  const saveSuccess = useSelector(selectSaveSuccess);
  const entityType = entityDefinition.name;
  const entityDisplayName = entityDefinition.displayName;
  const theseEntities = (allEntities && allEntities[entityType]) || [];
  const thisEntity = theseEntities.entities
    ? theseEntities.entities.find(entity => entity[theseEntities.entityDefinition.systemIdField] == activeEntityid)
    : null;
  const [signatureupload, setSignatureUpload] = React.useState(false);

  const closeModel = () => {
    setShowEditModel(false);
    // Reset save error and success status.
    dispatch(setSaveSuccessMessage(null));
    dispatch(setSaveSuccess(false));
  };
  const onClear = () => {
    dispatch(setSaveSuccessMessage(null));
    dispatch(setSaveSuccess(false));
  };
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    if (entityDisplayName === "User"){
      if (!signatureupload){
        setSubmitting(false);
        return;
      }
    }
    const newValues = { ...values };
    // Handle deletion of Multi-Nested values.
    // Formik can't delete a field value, set it to null and remove it here.
    Object.entries(newValues).map(([key, value]) => {
      if (Array.isArray(value)) {
        newValues[key] = value.filter(val => val);
      }

      if (typeof value === 'string') {
        newValues[key] = value.trim();
      }
    });
    if (activeEntityid) {
      dispatch(patchEntity({ entityDef: entityDefinition, entity: newValues, entityid: activeEntityid }));
    } else {
      dispatch(postEntity({ entityDef: entityDefinition, entity: newValues })).then(v => {
        resetForm({});
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    // Close this editor model only if update is successful.
    if (saveSuccess === true) {
      closeModel();
      if (saveSuccessMessage) {
        toast({
          title: saveSuccessMessage,
          position: 'top-right',
          status: 'success',
          duration: null,
          isClosable: true,
        });
      }
    }
  }, [saveSuccess, saveSuccessMessage]);

  return (
    <div className="modal-editor">
      {showEditModel ? (
        <Modal isOpen onClose={() => closeModel()} closeOnOverlayClick={false}>
          <ModalOverlay />
          <Formik
            initialValues={generateDefaultValues(theseEntities, thisEntity, activeEntityid, allEntities)}
            validationSchema={generateYupSchema(entityDefinition, referencedEntities)}
            onSubmit={onSubmit}
          >
            {({ errors, isSubmitting, resetForm, values }) => (
              <Form>
                <ModalContent maxW="80%">
                  <ModalHeader borderBottomWidth="1px" fontSize="20px">
                    {activeEntityid ? `Edit` : `Create`}
                    {` ${entityDisplayName}`}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody padding="22px">
                    <Box>
                      {saveError && (
                        <Alert status="error">
                          <AlertIcon />
                          <AlertDescription>{saveError}</AlertDescription>
                        </Alert>
                      )}
                      <Wrap spacing="30px">
                        {entityDefinition.fields &&
                          entityDefinition.fields.filter(validField).map(field => {
                            return (
                              <FieldRender
                                errors={errors}
                                key={field.name}
                                field={field}
                                referencedEntities={referencedEntities}
                                thisEntity={thisEntity}
                                value={values[field.name]}
                                setSignatureUpload={setSignatureUpload}
                              />
                            );
                          })}
                      </Wrap>
                    </Box>
                  </ModalBody>
                  <ModalFooter borderTopWidth="1px" marginTop="26px" padding="16px 22px">
                    {entityDefinition.fields &&
                      entityDefinition.fields.filter(validField).map(field => {
                        const fieldName = field.name;
                        const containBlankOption =
                          field.containBlankOption !== undefined ? field.containBlankOption : true;
                        return (
                          !field.referenceType &&
                          field.dataType === 'boolean' &&
                          field.name === 'delisted' && (
                            <HStack key={fieldName} spacing="20px">
                              <FormLabel htmlFor={fieldName} margin="0px">
                                {field.displayName}
                                {field.tooltip && (
                                  <Tooltip content={field.tooltip} placement="right">
                                    <IconButton
                                      width="14px"
                                      height="14px"
                                      padding="0"
                                      minW="auto"
                                      borderRadius="50%"
                                      color="#878787"
                                      icon={<IoInformationCircleOutline size="14px" />}
                                      variant="unstyled"
                                    />
                                  </Tooltip>
                                )}
                              </FormLabel>
                              <Field
                                as={SimpleReferenceSelectField}
                                fieldName={fieldName}
                                name={fieldName}
                                id={fieldName}
                                className="form-control"
                                options={[
                                  {
                                    label: 'No',
                                    value: 'false',
                                  },
                                  {
                                    label: 'Yes',
                                    value: 'true',
                                  },
                                ]}
                                controlStyles={{
                                  width: '150px',
                                }}
                                isClearable={false}
                              />
                              <FieldErrorMessage errors={errors} fieldName={fieldName} />
                            </HStack>
                          )
                        );
                      })}
                    {activeEntityid && entityDefinition.deletable && entityDefinition.deletable === true ? (
                      <Button
                        type="button"
                        variant="outline"
                        colorScheme="error"
                        width="163px"
                        height="43px"
                        onClick={() => {
                          const confirmation = window.confirm('Are you sure you want to delete item?');
                          if (confirmation) {
                            dispatch(delEntity({ entityDef: entityDefinition, entityid: activeEntityid }));
                            closeModel();
                          }
                        }}
                      >
                        <Text as="p" fontSize="16px" fontWeight="semibold">
                          Delete
                        </Text>
                      </Button>
                    ) : (
                      false
                    )}
                    <ButtonGroup marginLeft="auto">
                      <Button
                        type="button"
                        width="126px"
                        height="43px"
                        data-dismiss="modal"
                        onClick={() => {
                          resetForm(); // Reset the form fields
                          onClear(); // Clear the save success and error messages
                        }}
                      >
                        <Text as="p" fontSize="16px" fontWeight="black">
                          Clear
                        </Text>
                      </Button>
                      &nbsp;
                      <Button
                        type="submit"
                        width="126px"
                        height="43px"
                        colorScheme="actionPrimary"
                        isDisabled={isSubmitting}
                        marginLeft="10px"
                      >
                        <Text as="p" fontSize="16px" fontWeight="black">
                          {activeEntityid ? `Apply` : `Add`}
                        </Text>
                      </Button>
                    </ButtonGroup>
                  </ModalFooter>
                </ModalContent>
              </Form>
            )}
          </Formik>
        </Modal>
      ) : (
        false
      )}
    </div>
  );
};

export default EntityEditor;
